import React from 'react';
import {Route, Routes} from "react-router-dom";
import HomePage from "../page/homePage";
import WalletPage from "../page/walletPage";
import BoostPage from "../page/boostPage";
import TasksPage from "../page/tasksPage";
import WatchPage from "../page/tasksPage/pages/watchPage";
import JoinPage from "../page/tasksPage/pages/joinPage";
import InvitePage from "../page/tasksPage/pages/invitePage";
import ConnectPage from "../page/tasksPage/pages/connectPage";
import ReachPage from "../page/tasksPage/pages/reachPage";
import LevelPage from "../page/levelPage";
import StructurePage from "../page/structurePage";
import ReferralsTable from "../page/structurePage/referrals";
import StructureTable from "../page/structurePage/structure";


const Router = () => {


    return (
        <Routes>
            <Route path={'/'} element={<HomePage/>}/>
            <Route path={'/wallet'} element={<WalletPage/>}/>
            <Route path={'/boost'} element={<BoostPage/>}/>
            <Route path={'/level'} element={<LevelPage/>}/>
            <Route path={'/tasks'} element={<TasksPage/>}>
                <Route index element={<WatchPage/>}/>
                <Route path={'join'} element={<JoinPage/>}/>
                <Route path={'invite'} element={<InvitePage/>}/>
                <Route path={'connect'} element={<ConnectPage/>}/>
                <Route path={'reach'} element={<ReachPage/>}/>
            </Route>
            <Route path={'/structure'} element={<StructurePage/>}>
                <Route path={'referrals'} element={<ReferralsTable/>}/>
                <Route path={'structure'} element={<StructureTable/>}/>
            </Route>

            <Route path={'*'} element={<HomePage/>}/>
        </Routes>
    );
};

export default Router;