import React, {useContext, useState} from 'react';
import s from './styles.module.css'
import Title from "../../title";
import TaskItem from "../../item";
import {ReactComponent as VideoIcon} from "../../../../assets/tasks/video.svg";
import video from "../../../../assets/tasks/video.png";
import ModalDrawer from "../../../../components/modal";
import ContentModal from "./contentModal/inedx";
import {useSelector} from "react-redux";
import {selectTasksSlice} from "../../../../redux/slices/tasks.slice";
import {toast} from "react-toastify";
import {useOutletContext} from "react-router-dom";
import {useExploreStartMutation, useExploreVerifyMutation} from "../../../../redux/global.service";
import {UserContext} from "../../../../App";

const mock = [
    {id: 1, icon: <VideoIcon/>, title: 'Preparing for the flight', price: 500, isUsed: false},
    {id: 2, icon: <VideoIcon/>, title: 'What to do on a flight to avoid getting bored', price: 500, isUsed: true},
    {id: 3, icon: <VideoIcon/>, title: 'What to do if your flight is delayed or canceled', price: 500, isUsed: false},
    {id: 4, icon: <VideoIcon/>, title: 'The most popular places in 2024', price: 500, isUsed: false},
]

const WatchPage = () => {
    const [openMenu, setOpenMenu] = useState(false);
    const {tasks} = useSelector(selectTasksSlice)
    console.log(tasks)
    const {
        setUserData,
        setCount,
    } = useContext(UserContext)

    const [refetch] = useOutletContext();

    const [exploreStart, {isLoading: isLoadingStart}] = useExploreStartMutation()
    const [exploreVerify, {isLoading: isLoadingVerify}] = useExploreVerifyMutation()

    const toggleDrawer = (open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }
        setOpenMenu(open);
    };

    const handleStart = (data) => {
        if (data?.status === 'NotStarted') {
            exploreStart({type: data?.type, taskId: data?.taskId})
                .unwrap()
                .then((res) => {
                    console.log(res)
                    refetch()
                    setOpenMenu({...openMenu, status: res?.status})
                    const link = document.createElement('a')
                    link.href = data?.url
                    link.click()
                    link.remove()
                })
                .catch((e) => {
                    console.log(e)
                    toast.error('Error joinStart')
                })
        } else {
            const link = document.createElement('a')
            link.href = data?.url
            link.click()
            link.remove()
        }
    }
    const handleVerify = (data) => {
        console.log(data)
        exploreVerify({type: data?.type, taskId: data?.taskId, answer: data?.answer || ''})
            .unwrap()
            .then((res) => {
                console.log(res)
                setOpenMenu({...openMenu, status: res?.status})
                setUserData((prevData) => {
                    console.log(prevData)
                    return {...prevData, balance: prevData?.balance + data?.reward}
                })
                setCount((prevData) => {
                    console.log(prevData)
                    return prevData + data?.reward
                })
                refetch()
                toggleDrawer(false)
                toast.success('Completed')
            })
            .catch((e) => {
                console.log(e)
                toast.error((e?.data?.errors[0] && e?.data?.errors[0][0] && e?.data?.errors[0][0]) || 'Error verify')
            })
    }

    return (
        <div className={s.main}>
            <ModalDrawer openMenu={Boolean(openMenu)} toggleDrawer={toggleDrawer}>
                <ContentModal isLoadingStart={isLoadingStart} isLoadingVerify={isLoadingVerify} data={openMenu}
                              handleStart={handleStart} handleVerify={handleVerify}/>
            </ModalDrawer>

            {/*<Title>Watch the video</Title>*/}

            <div className={s.content}>
                {(tasks?.explore || [])?.map((el) => <TaskItem
                    status={el?.status}

                    icon={<img style={{height: '40px', width: '40px', objectFit: 'cover'}} alt={'icon'}
                               src={el?.iconUrl ? el?.iconUrl : video}/>}
                    handleChooseBoost={toggleDrawer(el)} {...el} type={'join'} key={el.taskId}/>)}   </div>
        </div>
    );
};

export default WatchPage;