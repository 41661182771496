import React, {useState} from 'react';
import s from './styles.module.css'
import {ReactComponent as HomeIcon} from "../../assets/navigate/home.svg";
import {ReactComponent as StructureIcon} from "../../assets/navigate/structure.svg";
import {ReactComponent as TasksIcon} from "../../assets/navigate/tasks.svg";
import {ReactComponent as BoostIcon} from "../../assets/navigate/boost.svg";
import {ReactComponent as WalletIcon} from "../../assets/navigate/wallet.svg";
import classNames from "classnames";
import {NavLink, useLocation} from "react-router-dom";

const Navigate = () => {
    const {pathname} = useLocation()
    const pathnameSplit = pathname?.split('/')?.at(-2)
    const pathnameStructure = pathname?.split('/')?.at(-2)

    return (
        <div className={s.navigate}>
            <div className={s.navigate_content}>
                <NavLink to={'/'}
                         className={classNames(s.navigate_item, pathname === '/' && s.navigate_item_active)}>
                    <HomeIcon/>
                    <p className={s.navigate_title}>Home</p>
                </NavLink>
                <NavLink to={'/structure/referrals'}
                         className={classNames(s.navigate_item, pathnameStructure === 'structure' && s.navigate_item_active)}>
                    <StructureIcon/>
                    <p className={s.navigate_title}>Structure</p>
                </NavLink>
                <NavLink to={'/tasks'}
                         className={classNames(s.navigate_item, (pathname === '/tasks' || pathnameSplit === 'tasks') && s.navigate_item_active)}>
                    <TasksIcon/>
                    <p className={s.navigate_title}>Tasks</p>
                </NavLink>
                <NavLink to={'/boost'}
                         className={classNames(s.navigate_item, pathname === '/boost' && s.navigate_item_active)}>
                    <BoostIcon/>
                    <p className={s.navigate_title}>Boost</p>
                </NavLink>
                <NavLink to={'/wallet'}
                         className={classNames(s.navigate_item, pathname === '/wallet' && s.navigate_item_active)}>
                    <WalletIcon/>
                    <p className={s.navigate_title}>Wallet</p>
                </NavLink>
            </div>
        </div>
    );
};

export default Navigate;