import React, {useState} from 'react';
import s from './styles.module.css'
import bird from "../../../../../assets/bird.png";
import Button from "../../../../../ui-kit/button";
import sucess from '../../../../../assets/suces.png'
import {spaceBtwHundreds} from "../../../../../utils/spaceBtw";
import {toast} from "react-toastify";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import LoaderBtn from "../../../../../components/laoder_btn";

const ContentModal = ({isLoadingStart, isLoadingVerify, data, handleClose, handleStart, handleVerify}) => {
    console.log(data)
    const titles = {
        'JoinTelegramTask': 'Subscribe to our Telegram channel',
        'JoinXTask': 'Subscribe to our Twitter channel',
        'JoinYoutubeTask': 'Subscribe to our YouTube channel',
    }
    const [answer, setAnswer] = useState('')

    return (
        <div className={s.content}>
            <h3 className={s.title}>{data?.title}</h3>
            <p className={s.description}>{data?.description}</p>

            <div className={s.box}>
                <p className={s.box_title}>{data?.question ? data?.question : ''}</p>
                {(data?.question && data?.status === 'Started') &&
                    <input type="text" className={s.input} value={answer} onChange={(e) => setAnswer(e.target.value)}
                           placeholder={'Write an answer'}/>}
                <div className={s.get_cost_box}>
                    <img src={bird} style={{width: '40px', height: '40px'}}
                         alt=""/> +{spaceBtwHundreds(data?.reward || 0)}
                </div>

                <Button className={s.btn_style} disabled={isLoadingStart} onClick={() => handleStart(data)}>
                    {isLoadingStart ? <LoaderBtn size={'small'}/> : (data?.status === 'NotStarted' ?
                        <div className={s.btn_content}>
                            Start <ArrowForwardIcon/>
                        </div> : <div className={s.btn_content}>
                            Go<ArrowForwardIcon/>
                        </div>)}

                </Button>
            </div>

            {data?.status === 'Started' && <Button disabled={isLoadingVerify} className={s.verif_btn} onClick={() => {
                if (data?.question && !answer) {
                    toast.error('Answer required')
                } else {
                    handleVerify({...data, answer: answer || ''})
                }
            }}>
                {isLoadingVerify ? <LoaderBtn/> : <>
                    Verify
                    <img src={sucess} alt="sucess"/>
                </>}


            </Button>}
        </div>
    );
};

export default ContentModal;