import React, {useContext, useEffect, useState} from 'react';
import s from './styles.module.css'
import Balance from "../../common/balance";
import BoostItem from "./item";
import ModalDrawer from "../../components/modal";
import BoostModalContent from "./boostModalContent";
import {UserContext} from "../../App";
import {
    useGetBoosterListCurrentQuery,
    useSetActiveBoostersMutation
} from "../../redux/global.service";
import {useDispatch, useSelector} from "react-redux";
import {selectBoosterSlice, setBoosters, setDaily} from "../../redux/slices/booster.slice";
import {toast} from "react-toastify";
import {Skeleton} from "@mui/material";
import LoadingPage from "../../components/loadingPage";

const BoostPage = () => {
    const dispatch = useDispatch()
    const {boosters, daily} = useSelector(selectBoosterSlice)
    console.log(boosters)
    const {isLoading} = useGetBoosterListCurrentQuery('', {
        refetchOnReconnect: true,
        refetchOnMountOrArgChange: true,
    })
    const [setActiveBoosters] = useSetActiveBoostersMutation()

    const {setCount, count} = useContext(UserContext)

    const [chooseBoost, setChooseBoost] = useState(false)

    const toggleDrawer = (open) => (event) => {
        console.log(open)
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }
        setChooseBoost(open);
    };

    // useEffect(() => {
    //     let i = 0
    //
    //     setInterval(() => {
    //         if (i === 87) {
    //             return
    //         } else {
    //             setActiveBoosters('Full Energy')
    //                 .unwrap()
    //                 .then((res) => {
    //                     i = i + 1
    //                     dispatch(setDaily({...daily, fullEnergy: {...daily?.fullEnergy, available: res?.available}}))
    //                     console.log(res)
    //                 })
    //                 .catch(e => console.log(e))
    //         }
    //     }, 500)
    // }, [])


    const handleBuy = (price) => {
        setActiveBoosters(chooseBoost?.title)
            .unwrap()
            .then((res) => {
                console.log(res)
                // res.level
                if (chooseBoost?.title === 'Turbo') {
                    dispatch(setDaily({...daily, turbo: {...daily?.turbo, available: res?.available}}))
                } else if (chooseBoost?.title === 'Full Energy') {
                    dispatch(setDaily({...daily, fullEnergy: {...daily?.fullEnergy, available: res?.available}}))
                } else if (chooseBoost?.title === 'Energy Limit') {
                    const maxLevel = Math.max(...boosters?.nextEnergyLimit?.info?.map((el) => el?.level))

                    dispatch(setBoosters({
                        ...boosters,
                        nextEnergyLimit: {
                            ...boosters?.nextEnergyLimit,
                            level: maxLevel === res?.level ? null : res.level + 1
                        }
                    }))
                } else if (chooseBoost?.title === 'Multitap') {
                    const maxLevel = Math.max(...boosters?.nextMultiTap?.info?.map((el) => el?.level))

                    dispatch(setBoosters({
                        ...boosters,
                        nextMultiTap: {...boosters?.nextMultiTap, level: maxLevel === res?.level ? null : res.level + 1}
                    }))

                } else if (chooseBoost?.title === 'Recharging speed') {
                    const maxLevel = Math.max(...boosters?.nextRechargingSpeed?.info?.map((el) => el?.level))

                    dispatch(setBoosters({
                        ...boosters,
                        nextRechargingSpeed: {
                            ...boosters?.nextRechargingSpeed,
                            level: maxLevel === res?.level ? null : res.level + 1
                        }
                    }))
                } else if (chooseBoost?.title === 'Auto tap bot') {
                    dispatch(setBoosters({
                        ...boosters,
                        autoTapBot: {
                            ...boosters?.autoTapBot,
                            activated: true
                            // level: maxLevel === res?.level ? null : res.level + 1
                        }
                    }))
                }
                setChooseBoost(false)
                setCount(count - price)
            })
            .catch((e) => {
                toast.error('Error buy')
                console.log(e)
            })
    }

    if (isLoading) {
        return <LoadingPage/>
    }
    return (
        <div className={s.main}>
            <ModalDrawer openMenu={Boolean(chooseBoost)} toggleDrawer={toggleDrawer}>
                <BoostModalContent handleBuy={handleBuy} count={count} content={chooseBoost}/>
            </ModalDrawer>

            <div className={s.balance_box}>
                {/*<p className={s.balance_title}>Your balance</p>*/}
                <Balance count={count} padding={'16px 0'}/>
            </div>

            <div className={s.boosts}>
                <h3 className={s.boost_title}>Daily Boosters</h3>
                <div className={s.boost_items}>
                    {[daily?.fullEnergy, daily?.turbo]?.map((el) => <BoostItem
                        handleChooseBoost={toggleDrawer({...el, icon: el?.icon || null, type: 1})} {...el}
                        key={el?.id}/>)}
                </div>
            </div>

            <div className={s.boosts}>
                <h3 className={s.boost_title}>Boosters</h3>
                <div className={s.boost_items}>
                    {[boosters?.nextMultiTap, boosters?.nextEnergyLimit, boosters?.nextRechargingSpeed, boosters?.autoTapBot]?.map((el) =>
                        <BoostItem
                            handleChooseBoost={toggleDrawer({
                                ...el,
                                price: el?.info?.find((f) => f?.level === el?.level)?.price || el?.price,
                                level: el?.info?.find((f) => f?.level === el?.level)?.level,
                                type: 2
                            })} type={2} {...el}
                            key={el?.id}/>)}
                </div>
            </div>
        </div>
    );
};

export default BoostPage;