import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {BrowserRouter} from "react-router-dom";
import 'swiper/css';
import {toast, ToastContainer} from "react-toastify";
import './toast.css';
import {Provider} from "react-redux";
import {storeRedux} from "./redux/store";
import {ErrorBoundary} from "react-error-boundary";

function ErrorHandler({error}) {

    return (
        <div role="alert" className={'error_container'}>
            <div className={'error_box'}>
                <h1>Go to the telegram application</h1>
                <a href="https://t.me/metabondcoin_game_bot">Go</a>
            </div>
        </div>
    )
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ErrorBoundary FallbackComponent={ErrorHandler}>
        <BrowserRouter>

            <ToastContainer
                position="top-center"
                autoClose={3000}
                limit={3}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable
                pauseOnHover
                theme="light"
            />
            <Provider store={storeRedux}>
                <App/>
            </Provider>
        </BrowserRouter>
    </ErrorBoundary>
);
