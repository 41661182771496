import React, {useContext} from 'react';
import s from './styles.module.css'
import {ReactComponent as CloseIcon} from "../../../assets/close.svg";
import {ReactComponent as WalletIcon} from "../../../assets/wallet.svg";
import {ReactComponent as WalletCoinIcon} from "../../../assets/wallet_coin.svg";
import {ReactComponent as PlayIcon} from "../../../assets/play.svg";
import Button from "../../../ui-kit/button";
import CopyBox from "../../../ui-kit/copyBox";
import {TonConnectButton, useTonConnectUI} from "@tonconnect/ui-react";
import {ConfigContext} from "../../../App";

const ConnectWallet = ({handleClose, setIsConnect, isConnect, value}) => {
    const [tonConnectUI] = useTonConnectUI();
    const {config} = useContext(ConfigContext)

    const handleDisconnect = async () => {
        const question = "Are you sure?";
        const result = window.confirm(question);
        if (result) {
            // Если пользователь нажал "ОК" (Да)
            console.log("User chose Yes");
            debugger
            await tonConnectUI.disconnect()
        } else {
            // Если пользователь нажал "Cancel" (Нет)
            console.log("User chose No");
        }

    }
    return (


        <div className={s.content}>
            <div className={s.content_wallet_icon}>
                {isConnect ? <WalletCoinIcon/> : <WalletIcon/>}
            </div>
            <div className={s.content_description}>
                <h3 className={s.title}>{isConnect ? "Wallet connected" : "Connect your wallet"}</h3>
                {isConnect ?
                    <p className={s.sub_title}>You can copy wallet address or disconnect it</p> :
                    <p className={s.sub_title}>If you don’t have one, watch the video and <br/> create it in
                        your
                        Telegram account</p>}
                <a href={config?.wallet} target={'_blank'}
                   className={s.tutorial_title}>
                    <PlayIcon/>
                    Learn more
                </a>
            </div>

            <div className={s.btns}>
                {isConnect ?
                    <CopyBox variant={2} copyValue={value} value={`${value?.slice(0, 4)}...${value?.slice(-4)}`}/> :
                    <TonConnectButton className="tonConnectButton" style={{width: '100%', maxWidth: '100%'}}/>
                }
                {isConnect &&
                    <Button onClick={handleDisconnect}
                            variant={2}>{'Disconnect your wallet'}</Button>}
            </div>
        </div>
    );
};

export default ConnectWallet;