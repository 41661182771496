import React from 'react';
import s from './styles.module.css'
import {NavLink, useLocation} from "react-router-dom";
import classNames from "classnames";

const Navigate = () => {
    const {pathname} = useLocation()
    const pathnameSplit = pathname?.split('/')?.at(-1)

    return (
        <div className={s.navigate}>
            <NavLink className={classNames(pathnameSplit === 'tasks' && s.active)} to={'/tasks'}>Explore</NavLink>
            <NavLink className={classNames(pathnameSplit === 'join' && s.active)} to={'join'}>Join</NavLink>
            <NavLink className={classNames(pathnameSplit === 'invite' && s.active)} to={'invite'}>Invite</NavLink>
            <NavLink className={classNames(pathnameSplit === 'connect' && s.active)} to={'connect'}>Connect</NavLink>
            <NavLink className={classNames(pathnameSplit === 'reach' && s.active)} to={'reach'}>Reach</NavLink>
        </div>
    );
};

export default Navigate;