import React from 'react';
import neonActive from '../assets/neon_active.png'
import neon from '../assets/neon.png'

const NeonIcon = ({
                      isActiveBoost,
                      classNames
                  }) => {
    if (isActiveBoost) {
        return <svg className={classNames} width="294" height="294" viewBox="0 0 294 294" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_f_355_7471)">
                <circle cx="147" cy="147" r="140" stroke="#05564C" strokeWidth="4" strokeDasharray="8 8"/>
            </g>
            <g filter="url(#filter1_f_355_7471)">
                <circle cx="147" cy="147" r="140" stroke="#078374" strokeWidth="3" strokeDasharray="8 8"/>
            </g>
            <g filter="url(#filter2_f_355_7471)">
                <circle cx="147" cy="147" r="140" stroke="#14BAA6" strokeWidth="2" strokeDasharray="8 8"/>
            </g>
            <g filter="url(#filter3_f_355_7471)">
                <circle cx="147" cy="147" r="140" stroke="#80F2E5" strokeDasharray="8 8"/>
            </g>
            <defs>
                <filter id="filter0_f_355_7471" x="0" y="0" width="294" height="294" filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                    <feGaussianBlur stdDeviation="2.5" result="effect1_foregroundBlur_355_7471"/>
                </filter>
                <filter id="filter1_f_355_7471" x="1.5" y="1.5" width="291" height="291" filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                    <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_355_7471"/>
                </filter>
                <filter id="filter2_f_355_7471" x="3" y="3" width="288" height="288" filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                    <feGaussianBlur stdDeviation="1.5" result="effect1_foregroundBlur_355_7471"/>
                </filter>
                <filter id="filter3_f_355_7471" x="5.5" y="5.5" width="283" height="283" filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                    <feGaussianBlur stdDeviation="0.5" result="effect1_foregroundBlur_355_7471"/>
                </filter>
            </defs>
        </svg>


    } else {
        return <svg width="294" height="294" viewBox="0 0 294 294" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_f_359_100)">
                <circle cx="147" cy="147" r="140" stroke="#083752" strokeWidth="4" strokeDasharray="8 8"/>
            </g>
            <g filter="url(#filter1_f_359_100)">
                <circle cx="147" cy="147" r="140" stroke="#174789" strokeWidth="3" strokeDasharray="8 8"/>
            </g>
            <g filter="url(#filter2_f_359_100)">
                <circle cx="147" cy="147" r="140" stroke="#349CD9" strokeWidth="2" strokeDasharray="8 8"/>
            </g>
            <g filter="url(#filter3_f_359_100)">
                <circle cx="147" cy="147" r="140" stroke="#ACE0FF" strokeDasharray="8 8"/>
            </g>
            <defs>
                <filter id="filter0_f_359_100" x="0" y="0" width="294" height="294" filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                    <feGaussianBlur stdDeviation="2.5" result="effect1_foregroundBlur_359_100"/>
                </filter>
                <filter id="filter1_f_359_100" x="1.5" y="1.5" width="291" height="291" filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                    <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_359_100"/>
                </filter>
                <filter id="filter2_f_359_100" x="3" y="3" width="288" height="288" filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                    <feGaussianBlur stdDeviation="1.5" result="effect1_foregroundBlur_359_100"/>
                </filter>
                <filter id="filter3_f_359_100" x="5.5" y="5.5" width="283" height="283" filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                    <feGaussianBlur stdDeviation="0.5" result="effect1_foregroundBlur_359_100"/>
                </filter>
            </defs>
        </svg>


    }
};

export default NeonIcon;