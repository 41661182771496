import {createSlice} from "@reduxjs/toolkit";
import {globalApi} from "../global.service";

export const boosterSlice = createSlice({
    name: 'booster',
    initialState: {
        boosters: null,
        daily: null,
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            globalApi.endpoints.getBoosterListCurrent.matchFulfilled,
            (state, {payload}) => {
                console.log(payload)
                state.daily = payload?.daily
                state.boosters = payload?.boosters
            },
        )
    },
    reducers: {
        setDaily: (state, action) => {
            state.daily = action.payload
        },
        setBoosters: (state, action) => {
            state.boosters = action.payload
        },
    }
})

export const {setDaily} = boosterSlice.actions
export const {setBoosters} = boosterSlice.actions

export const selectBoosterSlice = (state) => state?.booster;

export default boosterSlice.reducer
