import React from 'react';
import s from "./styles.module.css";
import {ReactComponent as CopyIcon} from "../../assets/copy.svg";
import classNames from "classnames";
import {toast} from "react-toastify";
import {ReactComponent as SuccessIcon} from "../../assets/successIcon.svg";
import {IconButton} from "@mui/material";

const CopyBox = ({value, variant = 2, copyValue}) => {
    const handleCopy = () => {

        navigator.clipboard.writeText(copyValue)
            .then(() => {
                toast.success('Copy to clipboard', {
                    icon: <SuccessIcon/>
                })
            })
            .catch((err) => {
                console.error('Failed to copy: ', err);
            });
    }
    return (
        <div className={classNames(s.wallet_connect_info_bottom, variant === 2 && s.wallet_connect_info_bottom_v2)}>
            <p>{value}</p>
            <IconButton onClick={handleCopy}>
                <CopyIcon/>
            </IconButton>
        </div>
    );
};

export default CopyBox;