import React, {useState} from 'react';
import s from './styles.module.css'
import Title from "../../title";
import TaskItem from "../../item";
import walletIcon from "../../../../assets/tasks/wallet.png";
import ModalDrawer from "../../../../components/modal";
import ConnectWallet from "../../../walletPage/connectWallet/connectWallet";
import {useSelector} from "react-redux";
import {selectTasksSlice} from "../../../../redux/slices/tasks.slice";

const ConnectPage = () => {
    const [isConnect, setIsConnect] = useState(false)
    const [openMenu, setOpenMenu] = useState(false);

    const {tasks} = useSelector(selectTasksSlice)
    console.log(tasks)

    const toggleDrawer = (open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }
        setOpenMenu(open);
    };

    return (
        <div className={s.main}>
            <ModalDrawer openMenu={openMenu} toggleDrawer={toggleDrawer}>
                <ConnectWallet isConnect={isConnect} setIsConnect={setIsConnect} handleClose={toggleDrawer(false)}/>
            </ModalDrawer>

            {/*<Title>Connect wallet</Title>*/}

            <div className={s.content}>
                {(tasks?.connect || [])?.map((el) => {
                    return <TaskItem {...el} key={el.taskId} type={'wallet'} handleChooseBoost={toggleDrawer(true)} isUsed={false}
                                     icon={<img style={{height: '40px', width: '40px'}} alt={'icon'}
                                                src={walletIcon}/>}/>
                })}

            </div>
        </div>
    );
};

export default ConnectPage;