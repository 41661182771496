import React, {useContext, useEffect, useState} from 'react';
import s from './styles.module.css'

import {ReactComponent as WalletIcon} from "../../assets/wallet.svg";
import {ReactComponent as WalletSmallIcon} from "../../assets/wallet_small.svg";
import {ReactComponent as ArrowIcon} from "../../assets/arrow.svg";

import Button from "../../ui-kit/button";
import CopyBox from "../../ui-kit/copyBox";
import {SwipeableDrawer} from "@mui/material";
import ConnectWallet from "./connectWallet/connectWallet";
import ModalDrawer from "../../components/modal";
import {useTonAddress, useTonWallet} from "@tonconnect/ui-react";
import {useSetWalletMutation} from "../../redux/global.service";
import {UserContext} from "../../App";

const WalletPage = () => {
    const [isConnect, setIsConnect] = useState(false)
    const [openMenu, setOpenMenu] = useState(false);
    const wallet = useTonWallet();
    const userFriendlyAddress = useTonAddress();
    const rawAddress = useTonAddress(false);

    const {
        userData,
        setUserData
    } = useContext(UserContext)

    const [setWallet] = useSetWalletMutation()

    const toggleDrawer = (open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }
        setOpenMenu(open);
    };

    // console.log(wallet)
    // console.log(rawAddress)
    console.log(userFriendlyAddress)
    console.log(userData, 'userData')
    useEffect(() => {
        if (userFriendlyAddress && !userData?.wallet) {
            setWallet({wallet: userFriendlyAddress})
                .then((res) => {
                    console.log(res)
                    setUserData({...userData, wallet: userFriendlyAddress})
                })
                .catch((e) => {
                    console.log(e)
                })
        } else if (!userFriendlyAddress && userData?.wallet) {
            setWallet({wallet: ''})
                .then((res) => {
                    setUserData({...userData, wallet: null})
                    console.log(res)
                })
                .catch((e) => {
                    console.log(e)
                })
        }
    }, [userFriendlyAddress, userData?.wallet])

    return (
        <div className={s.main}>

            <ModalDrawer openMenu={openMenu} toggleDrawer={toggleDrawer}>
                <ConnectWallet value={userFriendlyAddress} isConnect={Boolean(wallet)} setIsConnect={setIsConnect}
                               handleClose={toggleDrawer(false)}/>
            </ModalDrawer>

            <WalletIcon/>

            <h3 className={s.title}>Connect your wallet</h3>

            {
                Boolean(wallet) ?
                    <p className={s.sub_title}>to access upcoming features</p> :
                    <p className={s.sub_title}>Connect your wallet to access <br/> upcoming features</p>
            }

            <div className={s.btns}>
                {Boolean(wallet) && <div className={s.wallet_connect_info}>
                    <div className={s.wallet_connect_info_top}>
                        <div className={s.wallet_connect_info_top_left}>
                            <WalletSmallIcon/>
                            <p className={s.wallet_connect_info_top_left_title}>Wallet connected</p>
                        </div>
                        <div className={s.wallet_connect_info_top_right} onClick={toggleDrawer(true)}>
                            <ArrowIcon/>
                        </div>
                    </div>

                    <CopyBox variant={1} copyValue={userFriendlyAddress}
                             value={`${userFriendlyAddress?.slice(0, 4)}...${userFriendlyAddress?.slice(-4)}`}/>

                </div>}

                <Button variant={1} onClick={toggleDrawer(true)}>
                    Connect wallet
                </Button>
            </div>


        </div>
    );
};

export default WalletPage;