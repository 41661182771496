import React, {useEffect} from 'react';
import s from './styles.module.css'
import {ReactComponent as UsedIcon} from "../../../assets/boosts/used.svg";
import {ReactComponent as UnUsedIcon} from "../../../assets/boosts/unused.svg";
import {ReactComponent as CurrencyIcon} from "../../../assets/boosts/currency.svg";
import classNames from "classnames";
import {spaceBtwHundreds} from "../../../utils/spaceBtw";
import {useTimer} from "react-timer-hook";
import moment from "moment";

const BoostItem = ({
                       handleChooseBoost,
                       type = 1,
                       title,
                       icon,
                       price,
                       level,
                       isUsed,
                       activated,
                       limit,
                       available,
                       info,
                       isAutoTap = false
                   }) => {
    const nextDay = moment().add(1, 'days').startOf('day');
    const {
        seconds,
        minutes,
        hours,
        days,
        isRunning,
        restart
    } = useTimer({
        expiryTimestamp: new Date(nextDay?.format()),
        autoStart: false
    });

    console.log(nextDay?.format())

    useEffect(() => {
        if (available === 0 && type === 1) {
            restart(new Date(nextDay?.format()), true)
        }
    }, [available, type])
    return (
        <div className={classNames(s.item, isUsed && s.item_used)}>
            <div className={s.item_left}>
                <div className={s.item_left_icon}>
                    {icon}
                </div>
                <div className={s.item_left_info}>
                    <p className={s.item_left_info_title}>{title}</p>

                    <div className={s.item_left_info_bottom}>
                        {
                            type === 1 &&
                            <p className={s.item_left_info_bottom_title}>{`${available}/${limit} ${available === 0 ? 'Reload after:' : 'Available'}`}
                                {available === 0 && <span
                                    style={{color: '#f3d060'}}>{`${hours >= 10 ? hours : `0${hours}`}:${minutes >= 10 ? minutes : `0${minutes}`}:${seconds >= 10 ? seconds : `0${seconds}`}`}</span>}
                            </p>
                        }
                        {(!level && type === 2 && !isAutoTap) ?
                            <p className={s.item_left_info_bottom_title}>MAX</p> : <>

                                {
                                    type === 2 &&
                                    <p className={s.item_left_info_bottom_title}>
                                        {!activated && <CurrencyIcon/>}{isAutoTap ? (activated ?
                                        <p className={s.activated}>on</p> : `${spaceBtwHundreds(price || 0)}`) : `${spaceBtwHundreds(info?.find((f) => f.level === level)?.price || 0)} / Level ${info?.find((f) => f.level === level)?.level || 0}`}
                                    </p>
                                }
                            </>}
                    </div>
                </div>
            </div>
            <div className={s.icon_box}
                 onClick={() => {
                     if ((type === 1 && available === 0) || (!level && type === 2 && !isAutoTap) || activated) {
                         return
                     } else if ((available !== 0)) {
                         handleChooseBoost()
                     }

                 }}
            >
                {((type === 1 && available === 0) || (!level && type === 2 && !isAutoTap) || activated) ? <UsedIcon/> :
                    <UnUsedIcon/>}
            </div>
        </div>
    );
};

export default BoostItem;