import {createSlice} from "@reduxjs/toolkit";
import {globalApi} from "../global.service";

export const tasksSlice = createSlice({
    name: 'tasks',
    initialState: {
        tasks: {}
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            globalApi.endpoints.getListTasks.matchFulfilled,
            (state, {payload}) => {
                console.log(payload)
                state.tasks = payload
            },
        )
    },
    // reducers: {
    //     setDaily: (state, action) => {
    //         state.daily = action.payload
    //     },
    //     setBoosters: (state, action) => {
    //         state.boosters = action.payload
    //     },
    // }
})

// export const {setDaily} = tasksSlice.actions
// export const {setBoosters} = tasksSlice.actions

export const selectTasksSlice = (state) => state?.tasks;

export default tasksSlice.reducer
