import {createApi} from '@reduxjs/toolkit/query/react'
import {baseQueryWithReauth} from "../api/baseQuery";
import {ReactComponent as Daily1} from "../assets/boosts/daily_1.svg";
import React from "react";
import {ReactComponent as Daily2} from "../assets/boosts/daily_2.svg";
import {ReactComponent as Boost4} from "../assets/boosts/boost_4.svg";
import {ReactComponent as Boost2} from "../assets/boosts/boost_2.svg";
import {ReactComponent as Boost3} from "../assets/boosts/boost_3.svg";
import {ReactComponent as BoostActiveIcon} from "../assets/boost_active.svg";
import autoTapIcon from '../assets/boosts/auto_tap_bot_icon.png'
import energyLimitIcon from '../assets/boosts/enenrgy_limit_icon.png'
import fullEnergyIcon from '../assets/boosts/full_energy_icon.png'
import multiTapIcon from '../assets/boosts/multi_tap_icon.png'
import rechargingIcon from '../assets/boosts/reacharging_speed_icon.png'

export const globalApi = createApi({
        reducerPath: 'globalApi',
        baseQuery: baseQueryWithReauth,
        tagTypes: ['authApi'],
        endpoints: (build) => ({
            login: build.mutation({
                query: (payload) => ({
                    url: `users/login`,
                    method: 'POST',
                    body: payload,
                }),
            }),
            getUserInfo: build.mutation({
                query: () => ({
                    url: `users/info`,
                    method: 'GET',
                }),
            }),
            getBoosterListCurrent: build.query({

                queryFn: async (arg, api, extraOptions, baseQuery) => {
                    const boosters = await baseQuery({
                        url: `boosters/current`,
                        method: 'GET',
                    })

                    const boostersInfo = await baseQuery({
                        url: `boosters`,
                        method: 'GET',
                    })
                    const boosterDataDaily = {
                        ...boosters?.data?.daily,
                        turbo: {
                            ...boosters?.data?.daily?.turbo,
                            title: 'Turbo',
                            icon: <BoostActiveIcon/>,
                            id: 1,
                        },
                        fullEnergy: {
                            ...boosters?.data?.daily?.fullEnergy,
                            title: 'Full Energy',
                            icon: <img src={fullEnergyIcon} style={{height: '40px', width: '40px'}} alt={'Full Energy'}/>,
                            id: 2,
                        },
                    }
                    const boosterDataBoosters = {
                        ...boosters?.data?.boosters,
                        autoTapBot: {
                            ...boosters?.data?.boosters?.autoTapBot,
                            title: 'Auto tap bot',
                            id: 1,
                            isAutoTap: true,
                            icon: <img src={autoTapIcon} style={{height: '40px', width: '40px'}} alt={'Auto tap bot'}/>,
                        },
                        nextEnergyLimit: {
                            ...boosters?.data?.boosters?.nextEnergyLimit,
                            title: 'Energy Limit',
                            id: 2,
                            icon: <img src={energyLimitIcon} style={{height: '40px', width: '40px'}} alt={'Energy Limit'}/>,
                            info: boostersInfo?.data?.energyLimit
                        },
                        nextMultiTap: {
                            ...boosters?.data?.boosters?.nextMultiTap,
                            title: 'Multitap',
                            id: 3,
                            icon: <img src={multiTapIcon} style={{height: '40px', width: '40px'}} alt={'Multitap'}/>,
                            info: boostersInfo?.data?.multiTap
                        },
                        nextRechargingSpeed: {
                            ...boosters?.data?.boosters?.nextRechargingSpeed,
                            title: 'Recharging speed',
                            icon: <img src={rechargingIcon} style={{height: '40px', width: '40px'}}
                                       alt={'Recharging speed'}/>,
                            id: 4,
                            info: boostersInfo?.data?.rechargingSpeed
                        },
                    }

                    const boosterData = {daily: boosterDataDaily, boosters: boosterDataBoosters}

                    return {...boosters, data: boosterData}
                }
            }),
            setActiveBoosters: build.mutation({
                query: (type) => {
                    const url =
                        (type === 'Turbo' && 'boosters/consume-turbo') ||
                        (type === 'Full Energy' && 'boosters/consume-full-energy') ||
                        (type === 'Energy Limit' && 'boosters/buy-energy-limit') ||
                        (type === 'Multitap' && 'boosters/buy-multi-tap') ||
                        (type === 'Recharging speed' && 'boosters/buy-recharging-speed') ||
                        (type === 'Auto tap bot' && 'boosters/buy-auto-bot')
                    return {
                        url: url,
                        method: 'POST',
                    }
                },

            }),
            setBalance: build.query({
                query: (type) => {

                    return {
                        url: 'payments',
                        method: 'POST',
                        body: {amount: type}
                    }
                },

            }),
            clearAll: build.query({
                query: (type) => {

                    return {
                        url: 'boosters/profile',
                        method: 'DELETE',
                    }
                },

            }),
            getListTasks: build.query({
                queryFn: async (arg, api, extraOptions, baseQuery) => {
                    const currentTasks = await baseQuery({
                        url: `tasks/current`,
                        method: 'GET',
                    })

                    // const tasksInfo = await baseQuery({
                    //     url: `tasks`,
                    //     method: 'GET',
                    // })
                    return currentTasks
                }
            }),
            inviteCompleted: build.mutation({
                query: () => {
                    return {
                        url: 'https://staging-botapi.mtbco.in/test-reg',
                        method: 'GET',
                    }
                },
            }),
            setWallet: build.mutation({
                query: ({wallet}) => {
                    return {
                        url: 'users/wallet',
                        method: 'PATCH',
                        body: {
                            "wallet": wallet
                        }
                    }
                },
            }),
            joinStart: build.mutation({
                query: ({type, taskId}) => {
                    const urls =
                        (type === 'JoinTelegramTask' && `join-telegram-tasks/${taskId}/start`) ||
                        (type === 'JoinXTask' && `join-x-tasks/${taskId}/start`) ||
                        (type === 'JoinYoutubeTask' && `join-youtube-tasks/${taskId}/start`)
                    return {
                        url: urls,
                        method: 'POST',
                        body: {}
                    }
                },
            }),
            joinVerify: build.mutation({
                query: ({type, taskId, answer = ''}) => {
                    const urls =
                        (type === 'JoinTelegramTask' && `join-telegram-tasks/${taskId}/verify`) ||
                        (type === 'JoinXTask' && `join-x-tasks/${taskId}/verify`) ||
                        (type === 'JoinYoutubeTask' && `join-youtube-tasks/${taskId}/verify`)
                    return {
                        url: urls,
                        method: 'POST',
                        body: {answer: answer}
                    }
                },
            }),
            exploreStart: build.mutation({
                query: ({type, taskId}) => {
                    const urls =
                        (type === 'PlayGameTask' && `play-game-tasks/${taskId}/start`) ||
                        (type === 'WatchYoutubeTask' && `watch-youtube-tasks/${taskId}/start`)
                    return {
                        url: urls,
                        method: 'POST',
                        body: {}
                    }
                },
            }),
            exploreVerify: build.mutation({
                query: ({type, taskId, answer = ''}) => {
                    const urls =
                        (type === 'PlayGameTask' && `play-game-tasks/${taskId}/verify`) ||
                        (type === 'WatchYoutubeTask' && `watch-youtube-tasks/${taskId}/verify`)
                    return {
                        url: urls,
                        method: 'POST',
                        body: {answer: answer}
                    }
                },
            }),
            getReferrals: build.query({
                query: ({page = 1, pageSize = 12}) => {
                    return {
                        url: `users/referrals`,
                        params: {page, pageSize},
                        method: 'GET',
                    }
                },
            }),
            getStructure: build.query({
                query: ({page = 1, pageSize = 12}) => {
                    return {
                        url: `users/structure`,
                        params: {page, pageSize},
                        method: 'GET',
                    }
                },
            }),
            getLevels: build.query({
                query: () => {
                    return {
                        url: `levels`,
                        method: 'GET',
                    }
                },
            }),
        }),
    }
)

export const {
    useLoginMutation,
    useGetReferralsQuery,
    useGetStructureQuery,
    useGetLevelsQuery,
    useExploreVerifyMutation,
    useExploreStartMutation,
    useJoinStartMutation,
    useJoinVerifyMutation,
    useSetWalletMutation,
    useInviteCompletedMutation,
    useSetBalanceQuery,
    useClearAllQuery,
    useGetListTasksQuery,
    useGetUserInfoMutation,
    useGetBoosterListCurrentQuery,
    useSetActiveBoostersMutation,
} = globalApi;
