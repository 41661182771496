import React from 'react';
import s from './styles.module.css'
import classNames from "classnames";

const Button = ({children, onClick, variant = 1, disabled = false, className}) => {
    return (
        <div className={classNames(s.btn, variant === 2 && s.btn_v2, disabled && s.btn_disabled, className)}
             onClick={() => !disabled ? onClick() : {}}>
            {children}
        </div>
    );
};

export default Button;