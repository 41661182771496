import {configureStore} from '@reduxjs/toolkit'
import {setupListeners} from '@reduxjs/toolkit/query'
import {globalApi} from "./global.service";
import boosterSlice from "./slices/booster.slice";
import tasksSlice from "./slices/tasks.slice";


export const storeRedux = configureStore({
    reducer: {
        [globalApi.reducerPath]: globalApi.reducer,
        booster: boosterSlice,
        tasks: tasksSlice,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(
            globalApi.middleware,
        ),
})

setupListeners(storeRedux.dispatch)
