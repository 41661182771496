// src/configLoader.js

// Create a global promise to load the config
let configLoadPromise;

export const loadConfig = async () => {
  try {
    const response = await fetch('/config.json'); // Or use an API endpoint
    if (!response.ok) {
      throw new Error('Failed to load config');
    }
    const config = await response.json();
    localStorage.setItem('config', JSON.stringify(config));
    console.log('Config loaded:', config);
    return config;
  } catch (error) {
    console.error('Error loading configuration:', error);
    throw error;
  }
};

// Initialize the configLoadPromise
export const initializeConfig = () => {
  if (!configLoadPromise) {
    // If not already initialized, create the promise
    configLoadPromise = loadConfig();
  }
  return configLoadPromise;
};

// This function is used by other files to await config loading
export const getConfig = async () => {
  await configLoadPromise; // Wait for the config to be fully loaded
  return JSON.parse(localStorage.getItem('config'));
};

// Load the config before rendering the app
await initializeConfig()
  .then(() => {
	  
	  console.log('config loaded from config loader');
  });