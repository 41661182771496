import React from 'react';
import s from './styles.module.css'

import LinearProgress, {linearProgressClasses} from '@mui/material/LinearProgress';
import {Stack, styled} from "@mui/material";

const BorderLinearProgress = styled(LinearProgress)(({theme}) => ({
    height: '14px',
    borderRadius: '15px',
    // [`&.${linearProgressClasses.colorPrimary}`]: {
    //     backgroundColor: 'red',
    //     ...theme.applyStyles('dark', {
    //         backgroundColor: theme.palette.grey[800],
    //     }),
    // },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: '15px',
        backgroundColor: 'linear-gradient(90deg, #174789 0%, #247CBB 45%, #349CD9 100%)',
        ...theme.applyStyles('dark', {
            backgroundColor: '#308fe8',
        }),
    },
}));
const Progress = ({value = 30, children}) => {
    return (
        <div className={s.header_content_bottom}>
            <div className={s.children}>
                {children}
            </div>
            <Stack spacing={2} sx={{flexGrow: 1}}>
                <BorderLinearProgress variant="determinate" value={value}/>
            </Stack>
        </div>
        // <div className={s.header_content_bottom}>
        //     <div className={s.progress} style={{width: `calc(${value}% - 2px)`}}/>
        //     {children}
        // </div>

    );
};

export default Progress;