import React from 'react';
import s from "./styles.module.css";
import {ReactComponent as BoostActiveIcon} from "../../assets/boost_active.svg";
import {useTimer} from "react-timer-hook";
import classNames from "classnames";
import Progress from "../../common/progress";

const Timer = ({seconds}) => {

    return (
        <div className={classNames(s.boost,!seconds && s.boost_hidden)} style={{
            // opacity: !seconds ? 0 : 1
        }}>

            <div className={s.boost_box}>
                <div className={s.boost_item}>
                    <BoostActiveIcon/>
                    <p className={s.boost_main_title}>Boost</p>
                </div>
                <div className={classNames(s.boost_timer)}>

                    <div className={s.boost_item}>
                        <p className={s.boost_count}>{seconds >= 10 ? seconds : `0${seconds}`}</p>
                        <p className={s.boost_title}>Seconds</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Timer;