import React from 'react';
import s from './styles.module.css'
import {ReactComponent as CurrencyIcon} from "../../../assets/boosts/currency.svg";
import Button from "../../../ui-kit/button";
import {spaceBtwHundreds} from "../../../utils/spaceBtw";

const BoostModalContent = ({content, count, handleBuy}) => {
    console.log(content)
    return (
        <div className={s.main}>
            <div className={s.icon_box}>
                {content?.icon}
            </div>

            <div className={s.info_box}>
                <h3 className={s.title}>{content?.title}</h3>
                {content?.type === 2 && <p className={s.sub_title}>
                    {content?.title === "Energy Limit" && 'Increase the limit of energy storage'}
                    {content?.title === "Multitap" && 'Increase points per click limit'}
                    {content?.title === "Recharging speed" && 'Increase recharging speed'}
                </p>}
                {content?.type === 2 && <p className={s.sub_title_v2}>
                    {content?.title === "Energy Limit" && `Your limit increases to ${spaceBtwHundreds(content?.info?.find((f) => f.level === content?.level)?.energyLimit || 0)}`}
                    {content?.title === "Multitap" && `Your points per click increases to ${content?.info?.find((f) => f.level === content?.level)?.tapReward || 0}`}
                    {content?.title === "Recharging speed" && `Your recharging speed will be ${content?.info?.find((f) => f.level === content?.level)?.rechargingSpeed || 0} per second.`}
                </p>}

                {content?.type === 1 && <p className={s.sub_title}>Fill your energy to the max</p>}

            </div>

            <div className={s.coast_box}>
                <p className={s.coast_box_title}>
                    <CurrencyIcon/>{content?.type === 2 ? (content?.title === "Auto tap bot" ? spaceBtwHundreds(content?.price || 0) : `${spaceBtwHundreds(content?.price || 0)} / Level ${content?.level}`) : 'Free'}
                </p>
            </div>

            <div className={s.btn_box}>
                <Button disabled={((+count < +content?.price) && content?.type === 2)}
                        onClick={() => handleBuy(content?.type === 2 ? content?.price : 0)}>
                    {((+count < +content?.price) && content?.type === 2) ? 'Insufficient funds' : 'Get it'}
                </Button>
            </div>
        </div>
    );
};

export default BoostModalContent;