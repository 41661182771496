import React, {useState} from 'react';
import s from './styles.module.css'
import classNames from "classnames";
import {useGetReferralsQuery} from "../../../redux/global.service";
import cubok1 from "../../../assets/tasks/cubok_1.png";
import cubok2 from "../../../assets/tasks/cubok_2.png";
import cubok3 from "../../../assets/tasks/cubok_3.png";
import moment from "moment";
import {Pagination} from "@mui/material";
import LoadingPage from "../../../components/loadingPage";

const currentLevel = {
    'Bronze': cubok1,
    'Silver': cubok2,
    'Gold': cubok3,
}
const ReferralsTable = () => {
    const [page, setPage] = useState(1)
    const {data, isLoading} = useGetReferralsQuery({page: page}, {
        refetchOnReconnect: true,
        refetchOnMountOrArgChange: true,
    })
    console.log(data)

    if (isLoading) {
        return <div className={s.preloader}><LoadingPage type={2}/></div>
    }

    return (
        <div className={s.main}>
            <h3 className={s.title}>{`Referrals ${data?.totalCount || 0}`}</h3>

            {(data?.totalCount !== 0 && data) &&<div className={s.table}>
                <div className={s.table_header}>
                    <div className={classNames(s.table_header_item, s.table_header_item_first)}>Login</div>
                    <div className={s.table_header_right}>
                        <div style={{textAlign: 'center'}} className={classNames(s.table_header_item)}>Connection</div>
                        <div style={{textAlign: 'center'}}
                             className={classNames(s.table_header_item, s.table_content_item_level)}>Level
                        </div>
                        <div style={{textAlign: 'center'}} className={classNames(s.table_header_item)}>Reward</div>
                        <div style={{textAlign: 'center'}} className={classNames(s.table_header_item)}>Deep</div>
                    </div>
                </div>
                <div className={s.table_content}>
                    {data?.data?.map((el, i) => {
                        return <div key={el.tgUserId} className={s.item}>
                            <div className={classNames(s.table_content_item, s.table_header_item_first)}>
                                {el.name}
                            </div>
                            <div className={s.table_header_right}>
                                <div style={{textAlign: 'center'}}
                                     className={classNames(s.table_content_item)}>{moment(el.createdAt)?.format('DD.MM.YYYY')}</div>
                                <div style={{textAlign: 'center'}}
                                     className={classNames(s.table_content_item, s.table_content_item_level)}>
                                    <img src={currentLevel[el.level]} alt="level"/>
                                </div>
                                <div style={{textAlign: 'center'}}
                                     className={classNames(s.table_content_item)}>{`${el.reward}%`}</div>
                                <div style={{textAlign: 'center'}}
                                     className={classNames(s.table_content_item, s.table_content_item_counter)}>
                                    {el.deep}
                                </div>
                            </div>
                        </div>
                    })}
                </div>
                {(data?.totalPages && data?.totalPages > 1) && <div className={s.pagination}>
                    <Pagination count={data?.totalPages} onChange={(a, p) => {
                        // console.log(p)
                        setPage(p)
                    }
                    } color="primary" sx={{
                        '& ul li button': {
                            color: '#fff !important'
                        },
                        '& ul li div': {
                            color: '#fff !important'
                        }
                    }}/>
                </div>}
            </div>}
        </div>
    );
};

export default ReferralsTable;