import React, {useContext} from 'react';
import s from './styles.module.css'
import {ReactComponent as LogoIcon} from "../../assets/logo_count.svg";
import {motion} from "framer-motion";

import tg from '../../assets/preloader/tg.png'
import twitter from '../../assets/preloader/twitter.png'
import youtube from '../../assets/preloader/youtube.png'
import {ConfigContext} from "../../App";

const PreloaderPage = ({hidden}) => {
    const {config} = useContext(ConfigContext)

    const initial = hidden ? {
        opacity: 1
    } : {}
    const animate = hidden ? {
        opacity: 0,
        display: 'none'
    } : {}
    const transition = hidden ? {
        duration: 0.3, ease: "easeInOut"
    } : {}
    return (
        <motion.div
            initial={initial}
            animate={animate}
            transition={transition}
            className={s.main}>
            <h1 className={s.title}>MTB Coin</h1>
            <motion.div className={s.circle}>

                <motion.div
                    className={s.rotating_line}
                    initial={{rotate: 0}}
                    animate={{rotate: 360}}
                    transition={{duration: 4, ease: 'linear', repeat: Infinity}}
                />

                <motion.div initial={{height: '280px', width: '280px'}}
                            animate={{height: ['280px', '380px', '280px'], width: ['280px', '380px', '280px']}}
                            transition={{duration: 2, ease: "easeInOut", repeat: Infinity, delay: 0}}
                            className={s.circle_active}/>
                <motion.div initial={{height: '280px', width: '280px'}}
                            animate={{height: ['280px', '750px', '280px'], width: ['280px', '750px', '280px']}}
                            transition={{duration: 2, ease: "easeInOut", repeat: Infinity, delay: 0}}
                            className={s.circle_active_v2}/>

                <motion.div
                    className={s.logo}>
                    <LogoIcon/>
                </motion.div>
            </motion.div>

            <div className={s.info_box}>
                <p className={s.description}>Make, Tap, and Build Wealth with <br/>Friends!</p>

                <div className={s.social_link_box}>
                    <a href={config?.preloader_telegram} target={'_blank'}><img src={tg} alt="tg"/></a>
                    <a href={config?.preloader_x} target={'_blank'}><img src={twitter}
                                                                         alt="twitter"/></a>
                    <a href={config?.preloader_youtube} target={'_blank'}><img src={youtube}
                                                                               alt="youtube"/></a>
                </div>
            </div>
        </motion.div>
    );
};

export default PreloaderPage;