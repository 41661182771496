import React from 'react';
import {Box, CircularProgress} from "@mui/material";

const LoaderBtn = ({size}) => {
    return (
        <Box sx={{display: 'flex'}}>
            <CircularProgress size={size === 'small' ? 28 : 38} sx={{color: '#fff'}}/>
        </Box>
    );
};

export default LoaderBtn;