import React from 'react';
import s from './styles.module.css'
import Title from "../../title";
import TaskItem from "../../item";

import inviteIcon from "../../../../assets/tasks/invite.png";
import {useSelector} from "react-redux";
import {selectTasksSlice} from "../../../../redux/slices/tasks.slice";

const InvitePage = () => {
    const {tasks} = useSelector(selectTasksSlice)
    console.log(tasks)
    return (
        <div className={s.main}>
            {/*<Title>Invite friends</Title>*/}

            <div className={s.content}>
                {(tasks?.invite || [])?.map((el) => <TaskItem type={'invite'} isCompleted={el?.count >= el?.amount}
                                                              icon={<img style={{height: '40px', width: '40px'}}
                                                                         alt={'icon'} src={inviteIcon}/>}
                                                              isClaim={true} {...el}
                                                              key={el.taskId}/>)}
            </div>

        </div>
    );
};

export default InvitePage;