import React from 'react';
import s from './styles.module.css'
import Title from "../../title";
import cubok1Icon from "../../../../assets/tasks/cubok_1.png";
import cubok2Icon from "../../../../assets/tasks/cubok_2.png";
import cubok3Icon from "../../../../assets/tasks/cubok_3.png";
import TaskItem from "../../item";
import {useSelector} from "react-redux";
import {selectTasksSlice} from "../../../../redux/slices/tasks.slice";

const ReachPage = () => {
    const {tasks} = useSelector(selectTasksSlice)

    const icons = {
        "Silver": <img style={{height: '40px', width: '40px'}} alt={'icon'} src={cubok2Icon}/>,
        "Gold": <img style={{height: '40px', width: '40px'}} alt={'icon'} src={cubok3Icon}/>,
    }
    return (
        <div className={s.main}>
            {/*<Title>Reach a new level</Title>*/}

            <div className={s.content}>
                {(tasks?.reach || [])?.map((el) => <TaskItem type={'reach'} icon={icons[el?.level]}
                                                             isCompleted={el.current >= el.max}
                                                             isClaim={true} {...el}
                                                             key={el.taskId}/>)}
            </div>
        </div>
    );
};

export default ReachPage;