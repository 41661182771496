import React from 'react';
import s from './styles.module.css'
import Button from "../../ui-kit/button";
import {ReactComponent as CurrencyIcon} from "../../assets/boosts/currency.svg";
import {spaceBtwHundreds} from "../../utils/spaceBtw";

const AfkAmount = ({count = 0, handleClose}) => {

    return (
        <div className={s.main}>
            <div className={s.icon_box}>
                <CurrencyIcon/>
            </div>

            <div className={s.info_box}>
                <h3 className={s.title}>{`+${spaceBtwHundreds(count || 0)}`}</h3>

                <p className={s.sub_title}>
                    While you were away you got points.
                </p>
            </div>


            <div className={s.btn_box}>
                <Button
                    onClick={handleClose}>
                    Thanks
                </Button>
            </div>
        </div>
    );
};

export default AfkAmount;