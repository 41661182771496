import React, {useContext, useRef, useState} from 'react';
import s from './styles.module.css'
import cubok_1 from '../../assets/cubok_b_1.png'
import cubok_2 from '../../assets/cubok_b_2.png'
import cubok_3 from '../../assets/cubok_b_3.png'


import {Swiper, SwiperSlide} from 'swiper/react';
import {spaceBtwHundreds} from "../../utils/spaceBtw";
import Progress from "../../common/progress";
import {ReactComponent as ArrowIcon} from "../../assets/arrow.svg";
import {useGetLevelsQuery} from "../../redux/global.service";
import {UserContext} from "../../App";

const imgs = {
    "Bronze": cubok_1,
    "Silver": cubok_2,
    "Gold": cubok_3,
}


const LevelPage = () => {
    const swiperRef = useRef(null)
    const {userData} = useContext(UserContext)
    const [activeIndex, setActiveIndex] = useState(0)
    const {data} = useGetLevelsQuery('', {
        refetchOnReconnect: true,
        refetchOnMountOrArgChange: true,
    })
    console.log(activeIndex)
    return (
        <div className={s.main}>
            <div className={s.arrow_box}>
                <div style={{opacity: activeIndex === 0 ? 0 : 1}} className={s.arrow_left} onClick={() => {
                    swiperRef?.current?.slidePrev()
                    console.log('prev')
                }}><ArrowIcon/></div>
                <div style={{opacity: activeIndex === 2 ? 0 : 1}} className={s.arrow_right}
                     onClick={() => swiperRef?.current?.slideNext()}><ArrowIcon/>
                </div>
            </div>
            <Swiper
                spaceBetween={50}
                slidesPerView={1}
                onSlideChange={(e) => setActiveIndex(e?.activeIndex)}
                onSwiper={(swiper) => swiperRef.current = swiper}
            >
                {data?.data?.map((el, i) => {
                    return <SwiperSlide key={el.i}>
                        <div className={s.item}>
                            <h3 className={s.item_title}>{el.name}</h3>

                            <p className={s.item_description}>The number of coins determines <br/> the level of your cup
                            </p>

                            <div className={s.cubok}>
                                <img src={imgs[el.name]} alt={el.name}/>
                            </div>

                            <div className={s.info_box}>
                                {userData?.level === el.name ? '' : <>
                                    {(el?.from >= userData?.balance) && <h3 className={s.info_title}>
                                        {((userData?.balance || 0) >= el.from ? `${spaceBtwHundreds(el.from)} / ${spaceBtwHundreds(el.from)}` : `${spaceBtwHundreds(userData?.balance || 0)} / ${spaceBtwHundreds(el.from)}`)}
                                    </h3>}
                                    {(el?.from >= userData?.balance) &&
                                        <Progress
                                            value={(((userData?.balance || 0) >= el.from) ? (el.from / el.from) : (userData?.balance || 0) / el.from) * 100}></Progress>}
                                </>}
                                <h3 className={s.info_title}>
                                    {userData?.level === el.name ? 'Current Level' : (((userData?.balance || 0) >= el.from) && `Reached`)
                                        // (((userData?.balance || 0) >= el.to) && `Current Level`)
                                        // ((userData?.balance || 0) < el.to && `Current Level 2`)
                                    }
                                </h3>
                            </div>
                        </div>
                    </SwiperSlide>
                })}


            </Swiper>
        </div>
    );
};

export default LevelPage;