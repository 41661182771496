import React, {useContext} from 'react';
import s from './styles.module.css'
import {NavLink, Outlet, useLocation} from "react-router-dom";
import {ReactComponent as PlayIcon} from "../../assets/play.svg";
import {ReactComponent as CopyIcon} from "../../assets/copy.svg";
import inviteIcon from "../../assets/invite.png";
import Button from "../../ui-kit/button";
import classNames from "classnames";
import {ConfigContext, TeleContext, UserContext} from "../../App";
import {toast} from "react-toastify";
import {ReactComponent as SuccessIcon} from "../../assets/successIcon.svg";

const descriptionInfo = [
    {title: 'Share the invitation link'},
    {title: 'Your friends join MTB Coin'},
    {title: 'Get bonuses from your structure'},
]

const StructurePage = () => {
    const {config} = useContext(ConfigContext)

    const {userData} = useContext(UserContext)
    console.log(userData)
    const {pathname} = useLocation()

    const lastPath = pathname?.split('/')?.at(-1)

    const handleInviteFriends = () => {
        const inviteLink = userData?.refLink;
        const emoji = "🔥"; // Эмодзи
        console.log(inviteLink)
        const text = `${emoji} Make, Tap, and Build Wealth with Friends!`;
        if (window.Telegram && window.Telegram.WebApp) {
            // window.Telegram.WebApp.openLink(`https://t.me/share/text?url=${encodeURIComponent(inviteLink)}&text=${text}`);
            window.Telegram.WebApp.openTelegramLink(
                `https://t.me/share/url?url=${inviteLink}&text=\ud83d\udd25 Make, Tap, and Build Wealth with Friends!`
            );
        } else {
            console.error("Telegram WebApp API is not available");
        }

    };

    const handleCopy = () => {
        const inviteLink = userData?.refLink;

        navigator.clipboard.writeText(inviteLink)
            .then(() => {
                toast.success('Copy to clipboard', {
                    icon: <SuccessIcon/>
                })
            })
            .catch((err) => {
                console.error('Failed to copy: ', err);
            });
    }

    return (
        <div className={s.main}>
            <h3 className={s.title}>Invite your friends</h3>

            <div className={s.description_box}>
                <h3 className={s.description_box_title}>How it works</h3>
                <div className={s.description_box_items}>
                    {descriptionInfo?.map((el, i) => {
                        return <div className={s.item} key={i}>
                            <p className={s.number}>{`0${i + 1}`}</p>
                            <p className={s.item_title}>{el.title}</p>
                        </div>
                    })}
                </div>
                <div className={s.watch_video}>
                    <a href={config?.referral_program} target={'_blank'}
                       className={s.tutorial_title}>
                        <PlayIcon/>
                        More details
                    </a>
                </div>
            </div>

            {userData?.refLink && <div className={s.btns}>
                <Button className={s.btn} onClick={handleInviteFriends}>
                    Invite a friend
                    <img style={{height: '22px', width: '22px', marginLeft: '4px'}} src={inviteIcon} alt="inviteIcon"/>
                </Button>
                <Button onClick={handleCopy} className={classNames(s.btn, s.btn_copy)}><CopyIcon/></Button>
            </div>}

            <div className={s.navigate}>
                <NavLink className={classNames(s.link, lastPath === 'referrals' && s.link_active)}
                         to={'referrals'}>Referrals</NavLink>
                <NavLink className={classNames(s.link, lastPath === 'structure' && s.link_active)}
                         to={'structure'}>Structure</NavLink>
            </div>

            <div className={s.content}>
                <Outlet/>
            </div>
        </div>
    );
};

export default StructurePage;