import React, {useEffect, useState} from 'react';
import s from './styles.module.css'
import {spaceBtwHundreds} from "../../utils/spaceBtw";
import bird from '../../assets/bird.png'
import {useMotionValue, useTransform, motion, animate} from "framer-motion";

const Balance = ({count = 0, padding = '30px 0 16px 0'}) => {
    const motionCount = useMotionValue(count || 0);

    const [formattedCount, setFormattedCount] = useState(spaceBtwHundreds(count || 0));

    const animatedCount = useTransform(motionCount, (latest) => Math.floor(latest));

    useEffect(() => {
        animate(motionCount, count, {duration: 0.5});

        animatedCount.onChange((latest) => {
            setFormattedCount(spaceBtwHundreds(latest));
        });
    }, [count, motionCount, animatedCount]);

    return (
        <div className={s.counter} style={{
            padding: padding
        }}>
            <img src={bird} alt="bird"/>
            <motion.p>
                {formattedCount}
            </motion.p>
        </div>
    );
};

export default Balance;