import './App.css';
import { getConfig } from './configLoader'; // Import the config loader
import {createContext, useEffect, useState} from "react";
import Router from "./routers/router";
import Navigate from "./components/navigate";
import PreloaderPage from "./components/preloader";
import classNames from "classnames";
import {retrieveLaunchParams} from "@telegram-apps/sdk";
import {
    useClearAllQuery,
    useGetUserInfoMutation,
    useInviteCompletedMutation,
    useLoginMutation,
    useSetBalanceQuery
} from "./redux/global.service";
import {toast} from "react-toastify";
import Cookies from 'js-cookie';
import {useLocation} from "react-router-dom";
import axios from "axios";
import {TonConnectButton, TonConnectUIProvider} from "@tonconnect/ui-react";
import {ErrorBoundary} from "react-error-boundary";

const tele = window?.Telegram?.WebApp

export const TeleContext = createContext(null)
export const UserContext = createContext(null)
export const ConfigContext = createContext(null)



function App() {
    const {pathname} = useLocation()
    const [login, {isLoading: isLoadingProfile}] = useLoginMutation()
    const [getUserInfo, {isLoading: isLoadingUserInfo}] = useGetUserInfoMutation()

    const [count, setCount] = useState(0);
    const [pointPerClick, setPointPerClick] = useState(0);
    const [userData, setUserData] = useState({})
    const [showPreloader, setShowPreloader] = useState(true)
    const [socketData, setSocketData] = useState(null)
    const {initDataRaw: initData, initData: initDatav2} = retrieveLaunchParams();
    const [teleData, setTeleData] = useState(null)
    //const initData = 'query_id=AAHU9plfAAAAANT2mV8FsnV1&user=%7B%22id%22%3A1603925716%2C%22first_name%22%3A%22Alex%22%2C%22last_name%22%3A%22Thunder%22%2C%22username%22%3A%22MasterTatto%22%2C%22language_code%22%3A%22ru%22%2C%22allows_write_to_pm%22%3Atrue%7D&auth_date=1722949440&hash=561ea4c6b0ec29eab023cc84486281e5ec9e30af672d8a1c0344d67bd899a800'
	const [config, setConfig] = useState(null); // Store config after loading
	const [isLoadingConfig, setIsLoadingConfig] = useState(true); // Track loading state

    // const {data} = useSetBalanceQuery('63000')
    // const {} = useClearAllQuery()

    useEffect(() => {
        if (tele && initData && !isLoadingConfig) {
            // if (tele) {
            tele.ready()
            tele.expand()
            tele?.enableClosingConfirmation()
            tele?.disableVerticalSwipes()
            tele?.setBackgroundColor('#21202B')
            setTeleData(tele)

            // alert(initData)

            login({credentials: initData})
                .unwrap()
                .then((res) => {
                    localStorage.setItem('token',res?.token)
                    // Cookies.set('token', res?.token)
                    getUserInfo()
                        .unwrap()
                        .then((user) => {
                            console.log(user)
                            if (!window.signalR) {
                                toast.error('Error, try again.')
                                return
                            } else {
                                setCount(user?.balance)

                                setUserData(user)
                                setTimeout(() => {
                                    setShowPreloader(false)
                                }, 1000)
                            }

                        })
                        .catch((e) => {
                            console.log(e)
                            toast.error(`Error, try again. (${e?.status || 0})`)
                        })

                })
                .catch(e => {
                    console.log(e)
                    toast.error(`Error, try again. (${e?.status || 0})`)
                })
        }

    }, [tele, initData, isLoadingConfig])

    useEffect(() => {
        const iOS =
            typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);
        console.log(navigator.userAgent)
        if (iOS) {
            document.body.style.webkitTransform = 'translate3d(0, 0, 0)';
        }
    }, []);

    useEffect(() => {
        if (teleData && !isLoadingConfig) {
            getUserInfo()
                .unwrap()
                .then((user) => {
                    console.log(user)
                    if (!window.signalR) {
                        toast.error('Error, try again.')
                        return
                    } else {
                        setCount(user?.balance)
                        setUserData(user)

                    }

                })
                .catch((e) => {
                    console.log(e)
                    toast.error(`Error, try again. (${e?.status || 0})`)
                })
        }
    }, [pathname, isLoadingConfig])

  // Fetch and set config using the getConfig() method
  useEffect(() => {
	const loadConfigAsync = async () => {
	  try {
		const loadedConfig = await getConfig(); // Wait for the config to load
		setConfig(loadedConfig); // Set the loaded config in state
		setIsLoadingConfig(false); // Set loading to false
	  } catch (error) {
		console.error('Error loading config:', error);
		toast.error('Error loading config. Please try again.');
		setIsLoadingConfig(false); // In case of error, stop loading
	  }
	};

	loadConfigAsync(); // Call the async function to load config
  }, []); // Only run once on mount

    if (isLoadingConfig) return

    return (
            <TeleContext.Provider value={{
                tele: teleData
            }}>
                <ConfigContext.Provider value={{
                    config: config
                }}>
                    <TonConnectUIProvider
                        actionsConfiguration={{
                            twaReturnUrl: config?.tg_bot
                        }}
                        manifestUrl={config?.path_tonconnect_manifest}>
                        <UserContext.Provider value={{
                            userData: userData,
                            setUserData: setUserData,
                            socketData: socketData,
                            setSocketData: setSocketData,
                            count: count,
                            setCount: setCount,
                            pointPerClick: pointPerClick,
                            setPointPerClick: setPointPerClick
                        }}>
                            {/*<div onClick={test}>click</div>*/}
                            <div className={classNames('app', showPreloader && 'app_preloader')}>
                                <PreloaderPage hidden={!showPreloader}/>
                                {!showPreloader && <div className={'content'}>
                                    <Router/>
                                </div>}
                                <Navigate/>
                            </div>
                        </UserContext.Provider>
                    </TonConnectUIProvider>
                </ConfigContext.Provider>
            </TeleContext.Provider>
    );
}

export default App;
