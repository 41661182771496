import React, {useEffect} from 'react';
import s from './styles.module.css'
import {ReactComponent as UsedIcon} from "../../../assets/boosts/used.svg";
import {ReactComponent as UnUsedIcon} from "../../../assets/boosts/unused.svg";
import {ReactComponent as CurrencyIcon} from "../../../assets/boosts/currency.svg";
import classNames from "classnames";
import {spaceBtwHundreds} from "../../../utils/spaceBtw";
import Progress from "../../../common/progress";
import {useNavigate} from "react-router-dom";

const TaskItem = ({
                      handleChooseBoost,
                      title,
                      icon,
                      isUsed,
                      isClaim = false,
                      isCompleted = true,
                      amount,
                      count,
                      reward,
                      type,
                      status
                  }) => {
    const navigate = useNavigate()

    const progressValue = (count / amount) * 100; // Calculate the percentage
    console.log(status)
    const typeBtn = () => {
        switch (type) {
            case 'invite': {
                return <div className={s.icon_box} onClick={() => navigate('/structure/referrals')}>
                    {isCompleted ? <UsedIcon/> : <UnUsedIcon/>}
                </div>
            }
            case 'wallet': {
                const isCompleted = status !== 'NotStarted'
                return <div className={s.icon_box} onClick={() => isCompleted ? {} : navigate('/wallet')}>
                    {isCompleted ? <UsedIcon/> : <UnUsedIcon/>}
                </div>
            }
            case 'reach': {
                const isCompleted = status !== 'NotStarted'

                return <div className={s.icon_box} onClick={() => isCompleted ? {} : navigate('/level')}>
                    {isCompleted ? <UsedIcon/> : <UnUsedIcon/>}
                </div>
            }
            case 'join': {
                const isCompleted = status === 'Completed'

                return <div className={s.icon_box} onClick={handleChooseBoost}>
                    {isCompleted ? <UsedIcon/> : <UnUsedIcon/>}
                </div>
            }

            default: {
                return <div className={s.icon_box} onClick={(!isUsed && isCompleted) ? handleChooseBoost : () => {
                }}>
                    {isClaim ? (isUsed ? <UsedIcon/> :
                        <div className={classNames(s.btn, !isCompleted && s.btn_disabled)}>
                            Claim
                        </div>) : (isUsed ? <UsedIcon/> : <UnUsedIcon/>)}
                </div>
            }
        }
    }

    return (
        <div className={classNames(s.item, isUsed && s.item_used)}>
            <div className={s.item_wrapper}>
                <div className={s.item_left}>
                    <div className={s.item_left_icon}>
                        {icon}
                    </div>
                    <div className={s.item_left_info}>
                        <p className={s.item_left_info_title}>{title}</p>

                        <p className={s.item_left_info_bottom_title}>
                            <CurrencyIcon/>{`+${spaceBtwHundreds(reward || 0)}`}
                        </p>

                    </div>
                </div>

                {typeBtn()}
            </div>

            {isClaim && <div className={s.progress_box}>
                <Progress value={progressValue}>
                    <p className={s.progress_title}>{`${spaceBtwHundreds(count || 0)} / ${spaceBtwHundreds(amount || 0)}`}</p>
                </Progress>
            </div>}
        </div>
    );
};

export default TaskItem;