import React, {useContext, useEffect} from 'react';
import s from './styles.module.css'
import {Outlet, useLocation} from "react-router-dom";
import Balance from "../../common/balance";
import Navigate from "./navigate";
import {UserContext} from "../../App";
import {useGetListTasksQuery} from "../../redux/global.service";
import LoadingPage from "../../components/loadingPage";

const TasksPage = () => {
    const {count} = useContext(UserContext)
    const {pathname} = useLocation()
    const {data, isLoading, refetch} = useGetListTasksQuery('', {
        refetchOnReconnect: true,
        refetchOnMountOrArgChange: true,
    })

    if (isLoading) {
        return <LoadingPage/>
    }
    return (
        <div className={s.main}>
            <div className={s.balance_box}>
                {/*<p className={s.balance_title}>Your balance</p>*/}
                <Balance count={count} padding={'16px 0'}/>
            </div>
            <Navigate/>

            <div className={s.content}>
                <Outlet context={[refetch]}/>
            </div>
        </div>
    );
};

export default TasksPage;