import React from 'react';
import s from './styles.module.css'
import {ReactComponent as CubokLeftIcon} from "../../../assets/cubok_left.svg";
import {ReactComponent as CubokRightIcon} from "../../../assets/cubok_rigth.svg";
import {ReactComponent as CubokIcon} from "../../../assets/cubok.svg";
import {ReactComponent as ArrowIcon} from "../../../assets/arrow.svg";
import {ReactComponent as PowerIcon} from "../../../assets/power.svg";
import Progress from "../../../common/progress";
import {NavLink} from "react-router-dom";

const Header = ({userData}) => {

    return (
        <div className={s.header}>
            <div className={s.header_content}>
                <div className={s.cubok_left}>
                    <CubokLeftIcon/>
                </div>
                <div className={s.cubok_right}>
                    <CubokRightIcon/>
                </div>
                <NavLink to={'/level'} style={{textDecoration: 'none'}}>
                    <div className={s.header_content_top}>
                        <CubokIcon/>
                        <p className={s.header_content_top_title}>{userData?.level}</p>
                        <NavLink to={'/level'}>
                            <ArrowIcon/>
                        </NavLink>
                    </div>
                </NavLink>
                <Progress value={(userData?.energy / userData?.energyLimit) * 100}>
                    <p className={s.power_title}>
                        <PowerIcon/>
                        {`${userData?.energy || 0} / ${userData?.energyLimit || 0}`}
                    </p>
                </Progress>

            </div>
        </div>
    )
        ;
};

export default Header;