import React from 'react';
import s from './styles.module.css'
import {Skeleton} from "@mui/material";
import {motion} from "framer-motion";
import {ReactComponent as LogoIcon} from "../../assets/logo_count.svg";
import logoIcon from "../../assets/logo_count.svg";

const LoadingPage = ({type = 1}) => {
    return (
        <div className={s.skeleton}>
            {type === 1 ? <motion.div className={s.circle}>

                <motion.div
                    className={s.rotating_line}
                    initial={{rotate: 0}}
                    animate={{rotate: 360}}
                    transition={{duration: 4, ease: 'linear', repeat: Infinity}}
                />

                <motion.div initial={{height: '280px', width: '280px'}}
                            animate={{height: ['280px', '380px', '280px'], width: ['280px', '380px', '280px']}}
                            transition={{duration: 2, ease: "easeInOut", repeat: Infinity, delay: 0}}
                            className={s.circle_active}/>
                <motion.div initial={{height: '280px', width: '280px'}}
                            animate={{height: ['280px', '750px', '280px'], width: ['280px', '750px', '280px']}}
                            transition={{duration: 2, ease: "easeInOut", repeat: Infinity, delay: 0}}
                            className={s.circle_active_v2}/>

                <motion.div
                    className={s.logo}>
                    {/*<h1>wow</h1>*/}
                    <img src={logoIcon} alt=""/>
                    {/*<LogoIcon/>*/}
                </motion.div>
            </motion.div> : <Skeleton animation="wave" variant="rounded" width={'100%'} height={'100%'} sx={{
                backgroundColor: 'rgba(52,156,217,0.3)',
                '&::after': {
                    background: 'linear-gradient(90deg, transparent, rgba(52,156,217,0.6), transparent)'
                }
            }}/>}

        </div>
    );
};

export default LoadingPage;