import React, {useContext, useState} from 'react';
import s from './styles.module.css'
import Title from "../../title";
import TaskItem from "../../item";
import tgIcon from '../../../../assets/tasks/tg.png'
import youtubeIcon from '../../../../assets/tasks/yotube.png'
import twitterIcon from '../../../../assets/preloader/twitter.png'
import instagramIcon from '../../../../assets/tasks/instagram.png'
import discordIcon from '../../../../assets/tasks/discord.png'
import linkedinIcon from '../../../../assets/tasks/linkedin.png'
import tokyokIcon from '../../../../assets/tasks/tiktok.png'
import ModalDrawer from "../../../../components/modal";
import ConnectWallet from "../../../walletPage/connectWallet/connectWallet";
import ContentModal from "./contentModal/inedx";
import {useSelector} from "react-redux";
import {selectTasksSlice} from "../../../../redux/slices/tasks.slice";
import inviteIcon from "../../../../assets/tasks/invite.png";
import {useJoinStartMutation, useJoinVerifyMutation} from "../../../../redux/global.service";
import {toast} from "react-toastify";
import {useOutletContext} from "react-router-dom";
import {UserContext} from "../../../../App";
import fullEnergyIcon from "../../../../assets/boosts/full_energy_icon.png";

const mock = [
    {
        id: 1,
        icon: <img style={{height: '40px', width: '40px'}} alt={'icon'} src={tgIcon}/>,
        title: 'Join channel',
        titleModal: 'Telegram',
        price: 500,
        isUsed: false
    },
    {
        id: 2,
        icon: <img style={{height: '40px', width: '40px'}} alt={'icon'} src={youtubeIcon}/>,
        title: 'Subscribe YouTube',
        titleModal: 'YouTube',
        price: 500,
        isUsed: false
    },
    {
        id: 3,
        icon: <img style={{height: '40px', width: '40px'}} alt={'icon'} src={twitterIcon}/>,
        title: 'Follow us on Twitter',
        titleModal: 'Twitter',
        price: 500,
        isUsed: true
    },
    {
        id: 4,
        icon: <img style={{height: '40px', width: '40px'}} alt={'icon'} src={instagramIcon}/>,
        title: 'Follow us on Instagram',
        titleModal: 'Instagram',
        price: 500,
        isUsed: false
    },
    {
        id: 5,
        icon: <img style={{height: '40px', width: '40px'}} alt={'icon'} src={discordIcon}/>,
        title: 'Join channel',
        titleModal: 'Discord',
        price: 500,
        isUsed: true
    },
    {
        id: 6,
        icon: <img style={{height: '40px', width: '40px'}} alt={'icon'} src={linkedinIcon}/>,
        title: 'Follow us on LinkedIn',
        titleModal: 'LinkedIn',
        price: 500,
        isUsed: false
    },
    {
        id: 7,
        icon: <img style={{height: '40px', width: '40px'}} alt={'icon'} src={tokyokIcon}/>,
        title: 'Follow us on TikTok',
        titleModal: 'TikTok',
        price: 500,
        isUsed: false
    },
]
const JoinPage = () => {
    const {tasks} = useSelector(selectTasksSlice)
    console.log(tasks)
    const {
        setUserData,
        setCount,
    } = useContext(UserContext)
    const [refetch] = useOutletContext();
    const [openMenu, setOpenMenu] = useState(false);

    const [joinStart, {isLoading: isLoadingStart}] = useJoinStartMutation()
    const [joinVerify, {isLoading: isLoadingVerify}] = useJoinVerifyMutation()

    const icons = {
        'JoinTelegramTask': tgIcon,
        'JoinXTask': twitterIcon,
        'JoinYoutubeTask': youtubeIcon,
    }

    const handleStart = (data) => {
        if (data?.status === 'NotStarted') {
            joinStart({type: data?.type, taskId: data?.taskId})
                .unwrap()
                .then((res) => {
                    console.log(res)
                    refetch()
                    setOpenMenu({...openMenu, status: res?.status})
                    const link = document.createElement('a')
                    link.href = data?.url
                    link.click()
                    link.remove()
                })
                .catch((e) => {
                    console.log(e)
                    toast.error('Error joinStart')
                })
        } else {
            const link = document.createElement('a')
            link.href = data?.url
            link.click()
            link.remove()
        }
    }
    const handleVerify = (data) => {

        joinVerify({type: data?.type, taskId: data?.taskId, answer: data?.answer || ''})
            .unwrap()
            .then((res) => {
                console.log(res)
                setOpenMenu({...openMenu, status: res?.status})
                refetch()
                setUserData((prevData) => {
                    return {...prevData, balance: prevData?.balance + data?.reward}
                })
                setCount((prevData) => {
                    return prevData + data?.reward
                })
                toggleDrawer(false)
                toast.success('Completed')
            })
            .catch((e) => {
                console.log(e)
                toast.error((e?.data?.errors[0] && e?.data?.errors[0][0] && e?.data?.errors[0][0]) || 'Error verify')
            })
    }

    const toggleDrawer = (open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }
        setOpenMenu(open);
    };
    return (
        <div className={s.main}>

            <ModalDrawer openMenu={Boolean(openMenu)} toggleDrawer={toggleDrawer}>
                <ContentModal isLoadingStart={isLoadingStart} isLoadingVerify={isLoadingVerify}
                              handleVerify={handleVerify} handleStart={handleStart} handleClose={toggleDrawer(false)}
                              data={openMenu}/>
            </ModalDrawer>

            {/*<Title>Join social networks</Title>*/}

            <div className={s.content}>
                {(tasks?.join || [])?.map((el) => <TaskItem
                    status={el?.status}

                    icon={<img style={{height: '40px', width: '40px', objectFit: 'cover'}} alt={'icon'}
                               src={el?.iconUrl ? el?.iconUrl : icons[el?.type]}/>}
                    handleChooseBoost={toggleDrawer(el)} {...el} type={'join'} key={el.taskId}/>)}
            </div>
        </div>
    );
};

export default JoinPage;