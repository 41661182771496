import {fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import { getConfig } from '../configLoader'; // Import the config loader
import Cookies from 'js-cookie';

const baseQuery = fetchBaseQuery({
    baseUrl: (await getConfig())?.api,
    credentials: 'include',
    prepareHeaders: (headers, {getState}) => {
        // headers.set("Content-Type", "application/json");
        console.log(window.location?.hostname)
        const token = localStorage.getItem('token')
        if (token) {
            headers.set("Authorization", `Bearer ${token}`);
        }
        return headers
    },
})

export const baseQueryWithReauth = async (args, api, extraOptions) => {

    let result = await baseQuery(args, api, extraOptions);

    return result
}
