import React, {useContext, useEffect, useState} from 'react';
import s from './styles.module.css'
import {ReactComponent as CircleIcon} from "../../assets/circle_clicker.svg";
import {ReactComponent as PlainIcon} from "../../assets/air.svg";
import {AnimatePresence, motion} from "framer-motion";
import Timer from "./timer";
import Header from "./header";
import classNames from "classnames";
import World from "../../assets/world";
import Balance from "../../common/balance";
import {ConfigContext, UserContext} from "../../App";
import {toast} from "react-toastify";
import Cookies from 'js-cookie';
import NeonIcon from "../../assets/neon";
import Progress from "../../common/progress";
import ModalDrawer from "../../components/modal";
import AfkAmount from "../../components/afkAmount";

const HomePage = () => {
    const {config} = useContext(ConfigContext)

    const {
        socketData,
        setCount,
        setSocketData,
        setUserData,
        count,
        setPointPerClick,
        pointPerClick,
        userData
    } = useContext(UserContext)
    const [secondsTimer, setSecondsTimer] = useState(0)
    const [firstTimer, setFirstTimer] = useState(0)
    const [loading, setLoading] = useState(true)
    const [afk, setAfk] = useState(false)
    const [clicks, setClicks] = useState([]);

    const isNoEnergy = +userData?.energy < +pointPerClick
    // console.log(userData)
    const handleClick = (e) => {
        console.log('click')
        socketData.invoke("Tap")
            .then((res) => {
                console.log(res)
                if (isNoEnergy) {
                    return
                } else {
                    const newClick = {
                        x: e.clientX,
                        y: e.clientY - 8,
                        id: Date.now(),
                    };
                    setClicks((prevClicks) => {
                        const newClicks = [...prevClicks, newClick];
                        return newClicks.length > 10 ? newClicks.slice(-10) : newClicks;
                    });
                    setTimeout(() => {
                        setClicks((prevClicks) => prevClicks.filter(click => click.id !== newClick.id));
                    }, 500);
                }

            })
            .catch((e) => {
                console.log(e)
                toast.error('Error socket connection.')
            })
    };

    const toggleDrawer = (open) => (event) => {
        console.log(open)
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }
        setAfk(open);
    };

    useEffect(() => {
        const token = localStorage.getItem('token')
        if (token) {
            const hubUrl = config?.game_socket;

            const connection = new window.signalR.HubConnectionBuilder()
                .withUrl(hubUrl, {
                    accessTokenFactory: () => localStorage.getItem('token'),
                })
                .withAutomaticReconnect()
                .configureLogging(window.signalR.LogLevel.Information)
                .build();

            connection.start()
                .then((res) => {
                    console.log('SignalR connected', res)
                    setSocketData(connection)
                    setLoading(false)
                })
                .catch(err => console.error('SignalR connection error:', err));

            //только при клике
            connection.on("UpdateGameState", function (tapReward, energy) {
                // console.log(tapReward, 'tapReward')
                setUserData((prev) => ({...prev, energy: energy}))
                setPointPerClick(tapReward)
                setCount((prev) => prev + tapReward)
            });
            let minedAmountCount = 0
            // только при вызова Tick
            connection.on("UpdateGameEnv", function (energy, turboModeSecondsLeft, minedAmount) {
                // console.log(minedAmount, 'minedAmount')

                const countMined = minedAmount - minedAmountCount
                // console.log(countMined, 'countMined')
                setCount((prev) => prev + countMined)
                setUserData((prev) => ({...prev, energy: energy}))
                setSecondsTimer(turboModeSecondsLeft)
                setFirstTimer(turboModeSecondsLeft === 0 ? turboModeSecondsLeft : 0)
                minedAmountCount = minedAmount
            });

            connection.on("MiningNotify", function (minedAmount) {
                // console.log(minedAmount, 'minedAmount')
                setAfk(minedAmount)
            });

            let timerId = setInterval(() => connection.invoke("Tick") // отправка данных серверу
                .catch(function (err) {
                    console.log(err)
                }), 1000);


            return () => {
                connection.stop()
                    .then(() => {
                        console.log('Disconnected from SignalR');
                        clearInterval(timerId)
                    })
                    .catch(err => console.error('Error while disconnecting: ', err));
            }

        }

    }, [localStorage.getItem('token')])

    useEffect(() => {
        requestAnimationFrame(() => {
            document.body.style.height = 'auto'; // Пример изменения стиля для триггера отрисовки
        });
    }, []);

    useEffect(() => {
        // Скроллим на 1 пиксель вниз при монтировании компонента
        window.scrollTo(0, 1);
    }, []);
    return (
        <div className={classNames(s.main, loading && s.main_disabled)} onClick={(e) => {
            // e.preventDefault()
        }}>
            <ModalDrawer openMenu={Boolean(afk)} toggleDrawer={toggleDrawer}>
                <AfkAmount handleClose={toggleDrawer(false)} count={afk}/>
            </ModalDrawer>
            <Header userData={userData}/>
            <AnimatePresence>
                {clicks.map((click) => (
                    <motion.span
                        key={click.id}
                        initial={{opacity: 1, y: 0}}
                        animate={{opacity: 0, y: -130}}
                        exit={{opacity: 0}}
                        transition={{duration: 1}}
                        className={s.clickText}
                        style={{top: click.y, left: click.x}}
                    >
                        {`+${pointPerClick}`}
                    </motion.span>
                ))}
            </AnimatePresence>

            <Balance count={count}/>

            <div className={s.click}>
                <div className={s.circle_box}>
                    {/*<div className={classNames(s.circle_box_dashed, secondsTimer && s.circle_box_dashed_active)}/>*/}
                    <NeonIcon classNames={classNames(secondsTimer && s.neon_icon)} isActiveBoost={!!secondsTimer}/>
                    <motion.div
                        whileTap={{scale: 0.95}} // Применение масштаба при тапе
                        transition={{duration: 0.2}}
                        className={classNames(s.world_box,)}
                        onTapStart={handleClick}
                    >
                        <World color={'#078374'}/>
                    </motion.div>

                    <div className={classNames(s.air_box, secondsTimer && s.air_box_active)}>
                        <PlainIcon/>
                    </div>
                </div>
            </div>


            <div className={s.timer_box}>
                <Timer seconds={secondsTimer}/>
            </div>
        </div>
    );
};

export default HomePage;

