import React from 'react';
import {SwipeableDrawer} from "@mui/material";
import {ReactComponent as CloseIcon} from "../../assets/close.svg";
import s from './styles.module.css'

const ModalDrawer = ({openMenu, toggleDrawer, children}) => {
    return (
        <SwipeableDrawer
            sx={{
                zIndex: 998,
                '& .MuiBackdrop-root': {
                    background: `linear-gradient(180deg, rgba(5, 24, 49, 0.7) 21.74%, rgba(10, 31, 60, 0.7) 31.32%, rgba(26, 51, 85, 0.7) 44.52%, rgba(35, 144, 208, 0.7) 84.82%, rgba(49, 172, 244, 0.7) 100%) !important`

                },
                '& .MuiPaper-root': {
                    borderRadius: '20px 20px 0 0',
                    boxShadow: 'none',
                    overflow: 'visible',
                    background: '#21202B',
                },
            }}
            disableSwipeToOpen={true}
            anchor={'bottom'}
            open={openMenu}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
        >
            <div className={s.connect_wallet_box}>
                <div className={s.bg}/>

                <div className={s.connect_wallet}>
                    <div className={s.header}>
                        <div className={s.close_icon} onClick={toggleDrawer(false)}>
                            <CloseIcon/>
                        </div>
                    </div>
                    <div className={s.content}>
                        {children}
                    </div>
                </div>
            </div>
        </SwipeableDrawer>
    );
};

export default ModalDrawer;